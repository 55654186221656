@font-face {
	font-family: "Quicksand";
	src: url(./fonts/Quicksand-VariableFont_wght.ttf) format("truetype");
	font-style: normal;
	font-weight: 300 700;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-Thin.ttf) format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-Light.ttf) format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-Regular.ttf) format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-Medium.ttf) format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-Bold.ttf) format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-Black.ttf) format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-ThinItalic.ttf) format("truetype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-LightItalic.ttf) format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-Italic.ttf) format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-MediumItalic.ttf) format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-BoldItalic.ttf) format("truetype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Roboto";
	src: url(./fonts/Roboto-BlackItalic.ttf) format("truetype");
	font-weight: 900;
	font-style: italic;
}
